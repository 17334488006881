import React, {
  useCallback,
  useMemo,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useSelector } from "react-redux";
import { filter } from "rxjs/operators";

import {
  Table,
  Button,
  Icon,
  Segment,
  Header,
  Loader,
  Popup,
} from "semantic-ui-react";
import NavigateButton from "../../../../../../../components/NavigateButton";
import toastr from "toastr";
import SmallConfirmPopup from "../../../../../../../components/SmallConfirmPopup";
import { isManagerOrAdmin } from "../../../../../../../constants/rolesConstants";
import {
  changePaymentStatusApi,
  getApplicantsApi,
  deleteApplicantApi,
  changePhoneNumbers,
} from "../../../../../../../apis/applicantApi";
import { SOCKET_EVENTS } from "../../../../../../../constants/socketEvents";
import socketService from "../../../../../../../services/socketService";
import ModalButton from "../../../../../../../components/ModalButton";
import PhoneBook from "../../../../../../../components/PhoneBook";
import { PRODUCT_NAMES_MAPPING } from "../../constants/productNames";
// import ApplicantRefund from "../ApplicantRefund";
import { usePagination } from "../../../../../../../hooks/usePagination.hook";
import { useSubscription } from "../../../../../../../hooks/useSubscription.hook";
import { useSort } from "../../../../../../../hooks/useSort.hook";
import {
  getPaymentStatusString,
  PAYMENT_STATUSES,
} from "../../constants/applicantStatuses";
import ModalForm from "../../../../../../../components/common/ModalForm";
import MarkAsCapturedForm from "../ApplicantView/components/MarkAsCapturedForm";

const ApplicantsTable = (
  {
    api,
    normalizedFilters = {},
    filtersRendered = null,
    tableColumns,
    additionalActions = () => null,
    zendeskView,
  },
  ref,
) => {
  const { user } = useSelector((state) => {
    return {
      user: state.currentUser,
    };
  });

  const { sortParams, handleSortChange, normalizedSortParams } = useSort();

  const applicantsWrapper = useCallback(
    (query) => getApplicantsApi(query, api),
    [api],
  );

  const {
    items: applicants,
    Paginator,
    loadItems: loadApplicants,
    setItems: setApplicants,
    refreshItems: refreshApplicants,
    isLoading,
  } = usePagination(applicantsWrapper, {
    initialLoad: false,
    clearOnLoad: false,
  });

  useImperativeHandle(
    ref,
    () => ({
      setApplicants,
      refreshApplicants,
      isLoading,
    }),
    [setApplicants, refreshApplicants, isLoading],
  );

  useEffect(() => {
    loadApplicants(1, { ...normalizedFilters, ...normalizedSortParams });
  }, [loadApplicants, normalizedFilters, normalizedSortParams]);

  useSubscription(
    socketService.socketSubject,
    useCallback(
      (event) => {
        const {
          data: { paymentStatus },
          data: newApplicant,
        } = event;
        toastr.info(`New '${paymentStatus}' applicant has been added', 'Ok`);
        setApplicants((applicants = []) => [newApplicant, ...applicants]);
      },
      [setApplicants],
    ),
    [filter((event) => event.type === SOCKET_EVENTS[api].NEW_APPLICANT)],
  );

  useSubscription(
    socketService.socketSubject,
    useCallback(
      (event) => {
        const { _id, status } = event.data;
        setApplicants((applicants = []) =>
          applicants?.map((applicant) =>
            applicant._id === _id ? { ...applicant, status } : applicant,
          ),
        );
      },
      [setApplicants],
    ),
    [filter((event) => event.type === SOCKET_EVENTS[api].STATUS_CHANGED)],
  );

  useSubscription(
    socketService.socketSubject,
    useCallback(
      (event) => {
        const {
          data: { _id, paymentStatus },
        } = event;
        setApplicants((applicants = []) =>
          applicants?.map((applicant) =>
            applicant._id === _id ? { ...applicant, paymentStatus } : applicant,
          ),
        );
      },
      [setApplicants],
    ),
    [
      filter(
        (event) => event.type === SOCKET_EVENTS[api].PAYMENT_STATUS_CHANGED,
      ),
    ],
  );

  const deleteApplicantsHandler = useCallback(
    (applicantId) => async () => {
      try {
        await deleteApplicantApi(applicantId, api);
        setApplicants((applicants = []) =>
          applicants?.filter((applicant) => applicant._id !== applicantId),
        );
        toastr.success("Applicant successfully deleted!", "OK");
      } catch (error) {
        toastr.error("Applicant cannot be deleted!", "Error");
      }
    },
    [setApplicants, api],
  );

  const changePhoneNumberHandler = useCallback(
    (applicantId) => async (phoneNumbers) => {
      const {
        data: { data: newPhoneNumbers },
      } = await changePhoneNumbers(applicantId, phoneNumbers, api);
      setApplicants((applicants = []) =>
        applicants?.map((applicant) =>
          applicant._id !== applicantId
            ? applicant
            : { ...applicant, phoneNumbers: newPhoneNumbers },
        ),
      );
    },
    [setApplicants, api],
  );

  const actions = useMemo(() => {
    if (!applicants) {
      return;
    }
    return applicants.map((applicant) => {
      const { _id, paymentStatus } = applicant;

      let buttons = (
        <Popup
        content={"View/edit application"}
        trigger={
          <span>
            <NavigateButton
              route={`${api}/applicant/${_id}`}
              color="blue"
              size="mini"
              icon
            >
              <Icon name="edit" />
            </NavigateButton>
          </span>
        }
        />
      );
      if (paymentStatus === getPaymentStatusString(PAYMENT_STATUSES.Unpaid)) {
        buttons = (
          <Popup
          content={"Mark application as authorized/captured"}
          trigger={
            <span>
              <ModalForm
                headerText="Mark application as authorized/captured"
                loaderText="Preparing data..."
                onSubmit={(result) => 
                  changePaymentStatusApi(
                    _id,
                    PAYMENT_STATUSES.Captured,
                    api,
                    result?.orderId,
                  )
                }
                className="change-type-modal"
                closeOnSubmit
                trigger={
                  <Button color="green" size="mini" icon>
                    <Icon name="dollar" />
                  </Button>
                }
              >
                <MarkAsCapturedForm applicant={applicant} />
              </ModalForm>
           </span>
          }
        />
        );
      }

      if (user && isManagerOrAdmin(user.roles)) {
        buttons = (
          <React.Fragment>
            {buttons}
            <Popup
            content={"Delete application"}
            trigger={
              <span>
                <SmallConfirmPopup
                  header="Are you sure you want to delete the applicant?"
                  content={<Button positive floated="right" content="Delete" />}
                  callback={deleteApplicantsHandler(_id)}
                >
                  <Button color="red" size="mini" icon>
                    <Icon name="trash" />
                  </Button>
                </SmallConfirmPopup>
              </span>
            }
            />
          </React.Fragment>
        );
      }

      const { canCall, canSendMessage } = user.permissions;
      if (canCall || canSendMessage) {
        buttons = (
          <React.Fragment>
            {buttons}
            <Popup
            content={"Call the customer"}
            trigger={
              <span>
                <ModalButton
                  buttonProps={{ color: "green", size: "mini" }}
                  buttonClassName={"icon"}
                  buttonIcon="call"
                  modalComponent={
                    <PhoneBook
                      numbers={applicant.phoneNumbers}
                      applicant={applicant}
                      onChange={changePhoneNumberHandler(_id)}
                    />
                  }
                ></ModalButton>
              </span>
            }
            />
          </React.Fragment>
        );
      }

      // buttons = (
      //   <React.Fragment>
      //     {buttons}
      //     <ApplicantRefund applicant={applicant} api={api}>
      //       <Button color="red" size="mini" icon>
      //         <Icon name="dollar" />
      //       </Button>
      //     </ApplicantRefund>
      //   </React.Fragment>
      // );

      buttons = (
        <React.Fragment>
          {buttons}
          {additionalActions(applicant)}
        </React.Fragment>
      );

      return <Table.Cell className="action-buttons">{buttons}</Table.Cell>;
    });
  }, [
    applicants,
    api,
    changePhoneNumberHandler,
    deleteApplicantsHandler,
    user,
    additionalActions,
  ]);

  return (
    <Segment.Group style={{ display: "table", width: "100%" }}>
      <Segment className="tableTitle adminTable" inverted>
        <Header size="large" floated="left">
          {PRODUCT_NAMES_MAPPING[api]} Users
        </Header>
        {filtersRendered}
      </Segment>
      {!applicants && (
        <Segment>
          <Loader active />
        </Segment>
      )}
      {applicants && (
        <Table sortable celled striped>
          <Table.Header>
            <Table.Row>
              {Object.entries(tableColumns).map(([columnName, column]) => {
                const { label } = column;
                return (
                  <Table.HeaderCell
                    key={columnName}
                    sorted={
                      columnName === sortParams.sortCriteria
                        ? sortParams.sortOrder
                        : null
                    }
                    onClick={handleSortChange(columnName)}
                  >
                    {label}
                  </Table.HeaderCell>
                );
              })}
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {applicants.map((applicant, index) => (
              <Table.Row key={applicant._id}>
                {Object.entries(tableColumns).map(([columnName, column]) => {
                  const { value, color = () => null } = column;
                  const tdValue = value
                    ? columnName === "_id"
                      ? value(applicant, zendeskView)
                      : value(applicant)
                    : applicant[columnName];

                  return (
                    <Table.Cell
                      key={applicant.id + columnName}
                      style={{ backgroundColor: color(applicant) }}
                    >
                      {tdValue}
                    </Table.Cell>
                  );
                })}
                {actions[index]}
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={Object.keys(tableColumns).length + 2}>
                <Paginator />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      )}
    </Segment.Group>
  );
};

export default forwardRef(ApplicantsTable);
