import axios from "./httpClient";

export function getApplicantByIdApi(id, params, api = "") {
  return axios.get(`/${api}/applicant/${id}`, { params });
}
export function getApplicantLogsApi(id, api = "") {
  return axios.get(`/${api}/applicant/${id}/logs`);
}
export function getApplicantErrorsApi(id, api = "") {
  return axios.get(`/${api}/applicant/${id}/errors`);
}
export function getApplicantLastError(id, api = "") {
  return axios.get(`/${api}/applicant/${id}/last-error`);
}
export function getApplicantEmailsApi(id, api = "") {
  return axios.get(`/${api}/applicant/${id}/emails`);
}
export function updateApplicantApi(id, applicant, api = "", disableValidation) {
  return axios.put(`/${api}/applicant/${id}`, {
    ...applicant,
    disableValidation,
  });
}

export function changeApplicantType(
  id,
  applicationType,
  mainApplicationId,
  api = "",
) {
  return axios.post(`/${api}/applicant/${id}/application_type`, {
    applicationType,
    mainApplicationId,
  });
}

export function partyiallyUpdateApplicantApi(id, partialApplicant, api = "") {
  return axios.patch(`/${api}/applicant/${id}`, partialApplicant);
}

export function deleteApplicantApi(id, api = "") {
  return axios.delete(`/${api}/applicant/${id}`);
}

export function changePaymentStatusApi(id, status, api = "", orderId) {
  return axios.post(`/${api}/applicant/${id}/payment_status`, {
    status,
    orderId,
  });
}

export function changePhoneNumbers(id, phoneNumbers, api = "") {
  return axios.post(`/${api}/applicant/${id}/phone_numbers`, phoneNumbers);
}

export function changeStatusApi(id, status, api = "") {
  return axios.post(`/${api}/applicant/${id}/status`, { status });
}

export function changeFailsafeImmunity(id, failsafeImmunity, api = "") {
  return axios.post(`/${api}/applicant/${id}/immunity`, { failsafeImmunity });
}

export function runBotApi(id, api = "") {
  return axios.post(`/${api}/applicant/${id}/run`);
}

export function runPaymentBotApi(id, api = "") {
  return axios.post(`/${api}/applicant/${id}/run-payment`);
}

export function removeBotRunApi(id, api = "") {
  return axios.delete(`/${api}/applicant/${id}/run`);
}

export function runEmbassyBotApi(id, api = "") {
  return axios.post(`/${api}/applicant/${id}/run/embassy`);
}

export function removeEmbassyBotRunApi(id, api = "") {
  return axios.delete(`/${api}/applicant/${id}/run/embassy`);
}

export function getApplicantsApi(params, api = "") {
  return axios.get(`/${api}/applicant`, { params });
}

export function checkStatusApi(id, api = "") {
  return axios.post(`/${api}/applicant/${id}/check-status`);
}

export function continueApplicantApi(id, api = "") {
  return axios.post(`/${api}/applicant/${id}/continue`);
}

export function overrideApplicantApi(id, api = "") {
  return axios.post(`/${api}/applicant/${id}/override`);
}

export function changeApplicantStatusToPaymentFailed(id, api = "") {
  return axios.post(`/${api}/applicant/${id}/status`, { status: 29 });
}

export function uploadApplicantFileApi(id, api, fileType, body) {
  return axios.post(`/${api}/applicant/${id}/upload/${fileType}`, body);
}

export function refundApplicantsPaymentApi(
  id,
  api,
  orderId,
  refundAmount,
  reason,
) {
  return axios.post(`/${api}/applicant/${id}/invoices/${orderId}/refund`, {
    refundAmount,
    reason,
  });
}

export function changeScheduleStatusApi(id, api) {
  return axios.post(`/${api}/applicant/${id}/schedule_status`);
}

export function resendPhotoToolEmailApi(id, api) {
  return axios.post(`/${api}/applicant/${id}/photo-tool/resend`);
}

export function getGroupApplicants(id, api) {
  return axios.get(`/${api}/applicant/${id}/group-info`);
}

export function getDeclarationsApi(id) {
  return axios.get(`/review-and-certification/check-texts-ucc/${id}`);
}

export function submitIVisa(id) {
  return axios.post(`/esta/ivisa/${id}`);
}

export function cancelIVisa(id) {
  return axios.post(`/esta/ivisa/${id}/cancel`);
}

export function captureAuthorizationApi(id, api, orderId, amount) {
  return axios.post(`/${api}/applicant/${id}/capture-authorization`, {
    orderId,
    amount,
  });
}

export function cancelAuthorizationApi(id, api, orderId) {
  return axios.post(`/${api}/applicant/${id}/cancel-authorization`, {
    orderId,
  });
}

export function refundTransactionApi(id, api, orderId, amount) {
  return axios.post(`/${api}/applicant/${id}/refund-transaction`, {
    orderId,
    amount,
  });
}

export function validateApplicationApi(id, api) {
  return axios.post(
    `/${api}/applicant/${id}/validate`,
    { id, api },
    {
      timeout: 30000,
    },
  );
}

export function generatePassportAppointmentOptionsApi(id, api) {
  return axios.post(
    `/${api}/applicant/${id}/generate-passport-appointment-options`,
    { id, api },
    {
      timeout: 30000,
    },
  );
}

export function convertPassportApplicationApi(id, api) {
  return axios.post(
    `/${api}/applicant/${id}/convert-passport-application`,
    { id, api },
    {
      timeout: 30000,
    },
  );
}

export function getPortraitPictures(id, api) {
  return axios.get(`/${api}/applicant/${id}/portrait-pictures`, 
    { id, api },
    {
      timeout: 30000,
    }
  );
}
