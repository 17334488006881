import React from "react";
import { useEffect, useState, useCallback } from "react";
import { Icon, Loader } from "semantic-ui-react";
import { getPortraitPictures } from "../../../../../../../../../../apis/applicantApi";

const LoadingComponent = () => {
  return (
    <div style={{ width: "100%", position: "relative", height: "120px" }}>
      <Loader size="small" active>
        Loading
      </Loader>
    </div>
  );
};

const AlertFileNotFound = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "gray",
        gap: "10px",
      }}
    >
      <Icon name="id badge outline" size="large" />
      <div style={{ textAlign: "center" }}>Invalid/Not found Picture</div>
    </div>
  );
};

const PortraitPicture = ({ portrait }) => {
  const { id, name, url, uploadedAt } = portrait;
  const [error, setError] = useState(false);

  const handleImageError = () => {
    setError(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "200px",
      }}
    >
      <div
        style={{
          border: "1px solid #c2c2c2",
          borderRadius: "10px",
          padding: "20px 10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!error ? (
          <img
            style={{ width: "100%" }}
            src={url}
            alt={`Invalid/Not found Picture passport-${id}`}
            onError={handleImageError}
          />
        ) : (
          <AlertFileNotFound />
        )}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          gap: "2px",
          fontWeight: "bold",
          fontSize: "10pt",
          textOverflow: "clip"
        }}
      >
        <span>Uploaded on: {uploadedAt}</span>
      </div>
    </div>
  );
};

const PassportPortraitPhoto = ({ id, api }) => {
  const [isPortraits, setIsPortraits] = useState(true);
  const [portraits, setPortraits] = useState([]);
  const [loading, setLoading] = useState(false);

  const getPortraits = useCallback(async () => {
    setLoading(true);
    try {
        const { data } = await getPortraitPictures(id, api);
        if (!data.portraitPictures) {
            setPortraits([]);
            setIsPortraits(false);
            setLoading(false);
            return;
        }

        setPortraits(data.portraitPictures);
        setIsPortraits(true);
        setLoading(false);
        return;
        
    } catch (error) {
        console.log(error);
        setIsPortraits(false);
        setLoading(false);
    }
  }, [id, api, setPortraits, setIsPortraits, setLoading]);

  useEffect(() => {
    getPortraits();
  }, [getPortraits]);

  if (!loading && !isPortraits) return null;

  if (loading) return <LoadingComponent />;

  return (
    <div style={{ width: "100%", padding: "12px 0px" }}>
      <h3 style={{ color: "#545454" }}>Passport Portrait Photos</h3>
      {portraits ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "6px",
          }}
        >
          {portraits.map((portrait, index) => (
            <PortraitPicture
              key={`portrait-${index}-${portrait.id}`}
              portrait={portrait}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default PassportPortraitPhoto;
