import React, { useCallback, useState, useMemo, cloneElement } from "react";
import ReactDOM from "react-dom";
import { Button, Dimmer, Loader, Form } from "semantic-ui-react";
import "./ModalForm.styles.scss";
import renderConfirm from "../renderConfirm";
import { useSelector } from "react-redux";

const ModalForm = ({
  children,
  trigger,
  headerText,
  loaderText,
  extraButtons,
  submitButtonText,
  onSubmit,
  closeOnSubmit,
  confirmOnSubmitMessage,
  onOpen,
  onClose,
  parent,
  className,
  submitDisabled,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { currentUser } = useSelector((state) => ({
    currentUser: state.currentUser,
  }));

  const openModal = useCallback(() => {
    setOpen(true);
    if (onOpen) {
      onOpen();
    }
  }, [onOpen]);

  const closeModal = useCallback(() => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  trigger = useMemo(
    () =>
      trigger &&
      cloneElement(trigger, {
        onClick: () => {
          openModal();
        },
      }),
    [openModal, trigger],
  );

  parent = useMemo(() => document.querySelector(parent), [parent]);

  const handleSubmit = useCallback(
    async (e) => {
      const { target } = e;
      let result = true;
      if (confirmOnSubmitMessage) {
        result = await renderConfirm.show({
          message: confirmOnSubmitMessage,
          cancelButton: true,
        });
      }
      if (result) {
        const formData = new FormData(target);
        const result = {};

        const checkboxes = target.querySelectorAll("input[type=checkbox]")

        if(checkboxes.length > 0) {
          checkboxes.forEach((cb) => {
            if(cb.value === 'false')
              formData.append(cb.name, cb.value)
          })
        }

        formData.forEach((value, key) => {
          if (result[key]) {
            if (!Array.isArray(result[key])) {
              if(key === 'usConsulate') 
              result[key] = `${result[key]} ${value}`
              else
              result[key] = [result[key], value];
            } else {
              result[key].push(value);
            }
          } else {
            result[key] =
              document.getElementById(key) &&
              document.getElementById(key).multiple
                ? key === 'usConsulate' ? value : [value]
                : value;
          }
        });

        setLoading(true);
        await onSubmit(result);
        setLoading(false);
        if (closeOnSubmit) {
          closeModal();
        }
      }
    },
    [onSubmit, closeOnSubmit, closeModal, confirmOnSubmitMessage],
  );

  return (
    <>
      {trigger}
      {ReactDOM.createPortal(
        <>
          {open && (
            <div onClick={() => closeModal()} className={`modal-form ${className}`}>
              <div
                onClick={(e) => e.stopPropagation()}
                className="modal-form__body"
              >
                <Dimmer active={loading} inverted>
                  <Loader size="large" className="modal-form__body__dimmer">
                    {loaderText}
                  </Loader>
                </Dimmer>
                <Form onSubmit={handleSubmit}>
                  <h2>{headerText}</h2>
                  {children}
                  <Button
                    disabled={loading || submitDisabled}
                    type="submit"
                    className="right floated modal-form__button"
                  >
                    {submitButtonText ? submitButtonText : "Submit"}
                  </Button>
                  {extraButtons}
                  <Button
                    disabled={loading}
                    onClick={() => closeModal()}
                    basic
                    inverted={currentUser.settings.activeTheme === "dark"}
                    type="button"
                    className="right floated"
                  >
                    Cancel
                  </Button>
                </Form>
              </div>
            </div>
          )}
        </>,
        parent,
      )}
    </>
  );
};

ModalForm.defaultProps = {
  parent: "body",
};

export default ModalForm;
