export const ROLES = {
  ADMIN: "Admin",
  L1_AGENT: "L1 Agent",
  L1_MANAGER: "L1 Manager",
  L2_AGENT: "L2 Agent",
  L2_MANAGER: "L2 Manager",
  QA_AGENT: "QA Agent",
  QA_MANAGER: "QA Manager",
  BT_MANAGER: "BT Manager",
  BT_AGENT: "BT Agent",
};

export const MANAGER_ROLES = {
  [ROLES.L1_AGENT]: ROLES.L1_MANAGER,
  [ROLES.L2_AGENT]: ROLES.L2_MANAGER,
  [ROLES.QA_AGENT]: ROLES.QA_MANAGER,
  [ROLES.BT_AGENT]: ROLES.BT_MANAGER,
};

const ADMIN_AND_MANAGER_ROLES = [
  ROLES.ADMIN,
  ROLES.L1_MANAGER,
  ROLES.L2_MANAGER,
  ROLES.QA_MANAGER,
  ROLES.BT_MANAGER,
];

export function isManagerOrAdmin(roles) {
  const isAdmin = roles.some((role) => ADMIN_AND_MANAGER_ROLES.includes(role));
  return isAdmin;
}

export const AGENT_ROLES = [ROLES.L1_AGENT, ROLES.L2_AGENT, ROLES.QA_AGENT];

export function areOnlyAgentRolesWithoutBilling(roles) {
  if (!roles?.length) {
    console.error(
      "areOnlyAgentRolesWithoutBilling called withour roles",
      roles,
    );
    return false;
  }
  return roles.every((role) => AGENT_ROLES.includes(role));
}
